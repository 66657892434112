var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('component',[(_vm.session > 0)?_c('b-card',[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" Tambah Approval Sales ")])],1),_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Bisnis Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bisnis Unit","label-for":"bu","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.buOptions,"clearable":false,"input-id":"bu","placeholder":"Pilih Bisnis Unit"},on:{"input":function($event){return _vm.changedValue()}},model:{value:(_vm.userDataInfo.nama_bu),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "nama_bu", $$v)},expression:"userDataInfo.nama_bu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3866670639)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Divisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Divisi","label-for":"divisi","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.divOptions[0],"clearable":false,"input-id":"divisi","placeholder":"Pilih Divisi"},on:{"input":function($event){return _vm.changedValueSegmen()}},model:{value:(_vm.userDataInfo.div_desc),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "div_desc", $$v)},expression:"userDataInfo.div_desc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3155570236)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Segmen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Segmen","label-for":"segmen","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.segOptions[0],"clearable":false,"input-id":"segmen","placeholder":"Pilih Segmen"},on:{"input":function($event){return _vm.changedValueUser()}},model:{value:(_vm.userDataInfo.segmen_code),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "segmen_code", $$v)},expression:"userDataInfo.segmen_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,934863968)})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"User Approval","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"User Approval","label-for":"user app","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.namaOptions[0],"clearable":false,"input-id":"nama","placeholder":"Pilih User Approval"},model:{value:(_vm.userDataInfo.map_user_nik),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "map_user_nik", $$v)},expression:"userDataInfo.map_user_nik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4133967846)})],1),_c('b-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('validation-provider',{attrs:{"name":"Level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Level","label-for":"level","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.levelOptions,"clearable":false,"input-id":"nama","placeholder":"Pilih Level"},model:{value:(_vm.userDataInfo.app_level),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "app_level", $$v)},expression:"userDataInfo.app_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3025973066)})],1),_c('b-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Batas Bawah","label-for":"batas_bawah"}},[_c('validation-provider',{attrs:{"name":"Batas Bawah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"so_code","type":"number","placeholder":"input Batas Bawah","state":errors.length > 0 ? false:null},model:{value:(_vm.userDataInfo.app_batas_bawah),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "app_batas_bawah", $$v)},expression:"userDataInfo.app_batas_bawah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2548875165)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Batas Atas","label-for":"batas_atas"}},[_c('validation-provider',{attrs:{"name":"Batas Atas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"so_code","type":"number","placeholder":"input Batas Atas","state":errors.length > 0 ? false:null},model:{value:(_vm.userDataInfo.app_batas_atas),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "app_batas_atas", $$v)},expression:"userDataInfo.app_batas_atas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1153648957)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"label":"Aktif","label-for":"aktif","label-class":"mb-1"}},[_c('b-form-radio-group',{attrs:{"id":"menu_active","options":_vm.aktifOptions,"value":""},model:{value:(_vm.userDataInfo.app_status),callback:function ($$v) {_vm.$set(_vm.userDataInfo, "app_status", $$v)},expression:"userDataInfo.app_status"}})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{attrs:{"variant":"outline-secondary","to":{ name: 'apps-approval-sales-list'}}},[_vm._v(" Back ")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }